.indent {
  padding-left: 1.5em;
}

table.table {
  width: auto;
}

#login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
